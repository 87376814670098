













































































































import { InputSetups } from '@/mixins/input-setups'
import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'
import ModuleConfigSettingsWrapper from '@/components/Modules/components/ModuleConfigSettingsWrapper.vue'
import ModuleConfigSettings from '@/components/Modules/components/ModuleConfigSettings.vue'
import RestrictCommentHandlerModuleBuilder from '@/includes/logic/Modules/models/modules/RestrictCommentHandlerModule/RestrictCommentHandlerModuleBuilder'
import PunishActionSetting from "@/components/Modules/punish-actions/components/PunishActionSetting.vue";
import Placeholders from "@/mixins/placeholders/placeholders";
import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";

import NestedContent from 'piramis-base-components/src/components/Pi/components/NestedContent.vue'
import TimeUnitInput from 'piramis-base-components/src/components/Pi/fields/TimeUnitInput/TimeUnitInput.vue'
import { StepUnit } from 'piramis-base-components/src/components/PeriodSimplifier/types'
import {
  MediaEditorMode
} from "piramis-base-components/src/components/NewMessageEditors/types";
import MultiMessageEditorWithMediaInput from 'piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue'
import BlackWhiteListInput from 'piramis-base-components/src/components/Pi/fields/BlackWhiteListInput/BlackWhiteListInput.vue'

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component({
  data() {
    return {
      StepUnit,
      MediaEditorMode
    }
  },
  components: {
    PunishActionSetting,
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
    TimeUnitInput,
    NestedContent,
    MultiMessageEditorWithMediaInput,
    BlackWhiteListInput
  },
})
export default class RestrictCommentHandlerModuleView extends Mixins(ModuleBindings, InputSetups, Placeholders, TariffsTagsHelper) {
  @VModel() module!: RestrictCommentHandlerModuleBuilder
}
